class Logger {
    static log(message) {
        console.log(`[LOG] ${message}`);
    }

    static error(message) {
        console.error(`[ERROR] ${message}`);
    }
}

export default class AppConfig {
    static environment = AppConfig.getEnvironment();
    static build = '#{Build.BuildNumber}# #{Release.EnvironmentName}#';
    static release = '#{Release.ReleaseName}#';
    static appPrefix = 'SurplusInventory ';

    static events = {
        controllerActivateSuccess: 'controller.activateSuccess',
        spinnerToggle: 'spinner.toggle',
        controllerTitleUpdate: 'controller.titleUpdate'
    };

    static keyCodes = {
        backspace: 8,
        tab: 9,
        enter: 13,
        esc: 27,
        space: 32,
        pageup: 33,
        pagedown: 34,
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        insert: 45,
        del: 46
    };

    static remoteServiceName = 'controllers';

    static config = {
        appErrorPrefix: '[Surplus Inventory Error] ',
        docTitle: 'Surplus Inventory: ',
        events: self.events,
        keyCodes: self.keyCodes,
        remoteServiceName: self.remoteServiceName
    };

    // AzureAD Config - Get these values from the AzureAD application registration created by the Identity Team
    static aadInstance = 'https://login.microsoftonline.com/';
    static aadTenant = 'conocophillips.onmicrosoft.com';
    static aadClientId = AppConfig.getClientId();

    static aadRedirectEndpoints = {
        "http://localhost/SurplusInventory.API/": "https://ConocoPhillips.onmicrosoft.com/b4a3fee1-f6c3-440a-b4a8-419f55b11a3a", // Comment out to force external site
        "https://surplusinventoryapi-t.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/002ea91b-8e2a-45c9-b76f-b3c8db6ab253",
        "https://surplusinventoryapi-int-t.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/002ea91b-8e2a-45c9-b76f-b3c8db6ab253",
        "https://surplusinventoryapi.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/dbeb2640-b868-47cb-989e-b02b00cdd28d",
        "https://surplusinventoryapi-int.ca.cloudcopapps.net/": "https://ConocoPhillips.onmicrosoft.com/dbeb2640-b868-47cb-989e-b02b00cdd28d"
    };

    constructor() {
        Logger.log(`Environment: ${AppConfig.environment}`);
        Logger.log(`Build: ${AppConfig.build}`);
        Logger.log(`Release: ${AppConfig.release}`);
        Logger.log(`AAD Instance: ${AppConfig.aadInstance}`);
        Logger.log(`AAD Tenant: ${AppConfig.aadTenant}`);
        Logger.log(`AAD Client ID: ${AppConfig.aadClientId}`);
        Logger.log(`AAD Redirect Endpoints: ${JSON.stringify(AppConfig.aadRedirectEndpoints)}`);
    }

    static getEnvironment() {
        const environments = {
            'surplusinventory-int-t.ca.cloudcopapps.net': 'Test',
            'surplusinventory-t.ca.cloudcopapps.net' : 'Test',
            'surplusinventory-int.ca.cloudcopapps.net': 'Production',
            'surplusinventory.ca.cloudcopapps.net' : 'Production'

        };
        const environment = environments[location.host];
        Logger.log(`Selected environment for host ${location.host}: ${environment}`);
        return environment;
    }

    static getClientId() {
        const aadapplicationId = {
            'surplusinventory-int-t.ca.cloudcopapps.net': 'd8f704f6-458f-499d-8fd5-ced5107173f3',
            'surplusinventory-int.ca.cloudcopapps.net': '9bbefded-85a3-4f48-ae28-322334570136',
            'surplusinventory.ca.cloudcopapps.net' : '9bbefded-85a3-4f48-ae28-322334570136',
            'surplusinventory-t.ca.cloudcopapps.net' : 'd8f704f6-458f-499d-8fd5-ced5107173f3'
        };
        const clientId = aadapplicationId[location.host];
        Logger.log(`Selected client ID for host ${location.host}: ${clientId}`);
        return clientId;
    }

    static getRedirectEndpoint(url) {
        const endpoint = AppConfig.aadRedirectEndpoints[url];
        Logger.log(`Selected redirect endpoint for ${url}: ${endpoint}`);
        return endpoint;
    }
}